<template>
  <div id="traveler-snapshots-page">
    <NavOther/>
    <h1 class="page-title">#FeelMongolia</h1>
    <p class="text-center">
      <router-link to="feelmongolia" class="btn btn-danger">Find out more about our #FeelMongolia campaign</router-link>
    </p>
    <ImagesBoard :perPage="15" :grid="true" :columns="5" :rowHeight="260" :loadMore="true" :showWidows="true" />
    <NavrightHome/>
  </div>
</template>

<script>
import NavrightHome from "@/components/NavrightHome";
import NavOther from "@/components/NavOther";
import ImagesBoard from "@/components/ImagesBoard";

export default {
  name: "ew-images-board",
  components: {
    NavrightHome,
    NavOther,
    ImagesBoard
  }
};
</script>

<style lang="scss" scoped>
#traveler-snapshots-page {
  width: 80vw;
  margin: 100px auto;
  .page-title {
    text-align: center;
    color: #E13036;
    font-size: 60px;
    font-weight: bold;
    margin: 125px 0 45px;
  }

  a.btn-danger {
    background-color: #E13036;
    margin-bottom: 40px;
    border-radius: 0;
  }
}
</style>